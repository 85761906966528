import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Sphere } from '@react-three/drei';
import { pointsInner } from "./utils";
import getApp from '../assets/getApp.png';
import getAppMed from '../assets/getAppMed.png';
import getAppLarge from '../assets/getAppLarge.png';

const PlayersOnlyLanding = () => {
    return (
        <div className='relative'>

            <Canvas 
                camera={{position: [10, -7.5, -5]}}
                style={{backgroundColor: '#101010', width: "100vw", height: "100vh"}}>
                <OrbitControls maxDistance={20} minDistance={10}/>
                <directionalLight/>
                <pointLight position={[-30, 10, -30]}  power={10.0}/>
                <PointCircle/>
            </Canvas>
           
            <img style={{
                position: "absolute",
                zIndex: 1,
                left: "50%",
                top: "35%",
                transform: "translate(-50%)",
                margin: 0
                }}
                 srcSet={`${getApp} 300w, ${getApp} 768w, ${getAppMed} 1280w`}
                 sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                src={getApp}
                alt='logo'
            />
            
        </div>
    );
};

const PointCircle = () => {
    const ref = useRef();

    useFrame(({clock}) => {
        const time = clock.getElapsedTime() * 0.05;
        ref.current.rotation.z = time;
    });

    return (
            <group ref={ref} >
                 {pointsInner.map((point) => (
                    <Point key={point.idx} position={point.position} color={point.color} />
                ))}
            </group>
    );
};

const Point = ({position, color}) => {    
    return (
        <Sphere position={position} args={[0.1, 10, 10]}>
            <meshStandardMaterial 
                color={color}
                emissive={color}
                emissiveIntensity={0.5}
                roughness={0.5}
            />
        </Sphere>
    );
};

export default PlayersOnlyLanding;

